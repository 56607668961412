header
{
    margin: auto;
    padding-top:40px;
    text-align: center;
    height: 100%;
    background-color: #1373b9;
}

img{
    margin: auto;
}

